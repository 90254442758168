import { render, staticRenderFns } from "./TabPost.vue?vue&type=template&id=567a9b62&scoped=true&"
import script from "./TabPost.vue?vue&type=script&lang=js&"
export * from "./TabPost.vue?vue&type=script&lang=js&"
import style0 from "./TabPost.vue?vue&type=style&index=0&id=567a9b62&prod&lang=css&"
import style1 from "./TabPost.vue?vue&type=style&index=1&id=567a9b62&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "567a9b62",
  null
  
)

export default component.exports